import {
  actionLogReducer,
  authReducer,
  CoreAppState,
  customerProfileReducer,
  offeredServiceReducer,
  orderCardAttributeReducer,
  orderCardAttributeTypeReducer,
  orderCardReducer,
  orderReducer,
  orderServiceLinkageReducer,
  orderSourceReducer,
  orderStatusReducer,
  refreshTokenReducer,
  sidenavIsOpenReducer,
  staffProfileReducer,
  userReducer,
  userRoleAssignmentReducer,
  userRoleReducer,
} from '@aa/angular/core';
import { ActionReducer, createReducer, INIT, MetaReducer } from '@ngrx/store';

export interface StaffAppState extends CoreAppState {}

function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    console.log(`[ACTION]${action.type}`, action, state);

    return reducer(state, action);
  };
}

const hydrationMetaReducer = (
  reducer: ActionReducer<StaffAppState>,
): ActionReducer<StaffAppState> => {
  return (state, action) => {
    if (action.type === INIT) {
      const storageValue = localStorage.getItem('state');
      if (storageValue) {
        try {
          return JSON.parse(storageValue) as StaffAppState;
        } catch {
          localStorage.removeItem('state');
        }
      }
    }
    const nextState = reducer(state, action);
    const copyOfState = {
      auth: nextState?.['auth'],
    };
    localStorage.setItem('state', JSON.stringify(copyOfState));
    return nextState;
  };
};

export const staffAppMetaReducers: MetaReducer[] = [
  debug,
  hydrationMetaReducer,
];

export const staffAppReducers = {
  isStaffApp: createReducer(true),
  sidenavIsOpen: sidenavIsOpenReducer,
  auth: authReducer,
  order: orderReducer,
  orderCard: orderCardReducer,
  user: userReducer,
  userRole: userRoleReducer,
  userRoleAssignment: userRoleAssignmentReducer,
  refreshToken: refreshTokenReducer,
  actionLog: actionLogReducer,
  offeredService: offeredServiceReducer,
  orderServiceLinkage: orderServiceLinkageReducer,
  orderSource: orderSourceReducer,
  orderStatus: orderStatusReducer,
  orderCardAttribute: orderCardAttributeReducer,
  orderCardAttributeType: orderCardAttributeTypeReducer,
  customerProfile: customerProfileReducer,
  staffProfile: staffProfileReducer,
};
