import {
  actionLogActions,
  ActionLogsListViewComponent,
  ConfirmDialogComponent,
  ConfirmDialogData,
  orderActions,
  orderCardActions,
  OrderFormModalComponent,
  OrderFormModalData,
  orderSourceActions,
  orderStatusActions,
  selectOrderSourceState,
  selectOrderState,
  selectOrderStatusState,
  StorageService,
} from '@aa/angular/core';
import {
  ActionLogResourceTypeMappings,
  OrderCardResourceTypeMappings,
  OrderResourceTypeMappings,
  OrderSourceResourceTypeMappings,
  OrderStatusResourceTypeMappings,
  UpdateOrderCardDTO,
} from '@aa/nest/resource';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  of,
} from 'rxjs';
import { StaffAppState } from '../../state/index.reducers';

import {
  cardFormArrayFields,
  cardFormFields,
  CardFormModel,
  NewCardViewComponent,
} from '@aa/angular/customer';
import {
  computeTextColorForBackground,
  getActionLogSummaryString,
} from '@aa/ts/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import {
  DataTableComponent,
  DataTableConfig,
} from '../../../../../core/src/lib/components/data-table/data-table.component';

const flatLevelAttributes = ['value'];

@Component({
  selector: 'aa-order-detail-view',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    FormlyModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    NewCardViewComponent,
    ActionLogsListViewComponent,
    DataTableComponent,
  ],
  templateUrl: './order-detail-view.component.html',
  styleUrl: './order-detail-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailViewComponent {
  form = new FormGroup({});
  model: CardFormModel = {
    cardNumber: '',
    characterName: '',
    issuedYear: '',
    notes: '',
    playerName: '',
    pokemonType: '',
    productionCompany: '',
    serialNumber: '',
    setName: '',
    // tradingCardType: '',
    variation: '',
  };
  fields: FormlyFieldConfig[] = [
    {
      template: '<p><strong>Provided Details</strong><p/>',
    },
    ...cardFormFields,
    {
      template: '<p><strong>Staff Determined Details</strong></p>',
    },
    {
      key: 'value',
      type: 'number',
      props: {
        label: 'Value',
      },
    },
  ];

  summaryTableConfig: DataTableConfig = {
    data$: of([]),
    columns: [
      {
        key: 'attribute',
        label: 'Attribute',
        accessor: (row) => `${row.attribute}: `,
        getStyle: () => ({
          'font-weight': '700',
          'min-width': 'fit-content',
          'text-wrap': 'nowrap',
        }),
        getHeaderStyle: () => ({ minWidth: 'fit-content' }),
      },
      {
        key: 'value',
        label: 'Value',
        getStyle: () => ({ width: '100%' }),
        getHeaderStyle: () => ({ width: '100%' }),
      },
    ],
    numItems$: of(0),
    hidePagination: true,
    hideTableHeaders: true,
    hideShadow: true,
  };

  orderId$: Observable<number>;
  order$: Observable<
    OrderResourceTypeMappings['resourceWithRelationsT'] | undefined | null
  >;
  orderStatuses$: Observable<
    OrderStatusResourceTypeMappings['resourceWithRelationsT'][]
  >;
  orderSources$: Observable<
    OrderSourceResourceTypeMappings['resourceWithRelationsT'][]
  >;

  currentCard$ = new BehaviorSubject<
    OrderCardResourceTypeMappings['resourceWithRelationsT'] | null
  >(null);
  aiLoading$ = new BehaviorSubject<boolean>(false);

  actionLogsWhere?: ActionLogResourceTypeMappings['whereT'];
  actionLogsInclude?: ActionLogResourceTypeMappings['includeT'] = {
    user: {
      include: {
        staffProfile: true,
      },
    },
  };

  constructor(
    private readonly store: Store<StaffAppState>,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly storageService: StorageService,
  ) {
    this.order$ = this.store
      .select((s) => selectOrderState(s).current)
      .pipe(takeUntilDestroyed());
    this.orderStatuses$ = this.store
      .select((s) => selectOrderStatusState(s).items)
      .pipe(takeUntilDestroyed());
    this.orderSources$ = this.store
      .select((s) => selectOrderSourceState(s).items)
      .pipe(takeUntilDestroyed());

    this.orderId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('id')!)),
      takeUntilDestroyed(),
    );

    this.store.dispatch(orderStatusActions.loadItems({}));
    this.store.dispatch(orderSourceActions.loadItems({}));
    this.store.dispatch(
      actionLogActions.loadItems({
        query: {
          include: {
            user: {
              include: {
                staffProfile: true,
              },
            },
          },
          orderBy: {
            createdAt: 'desc',
          },
        },
      }),
    );

    this.orderId$.subscribe((orderId) => {
      this.store.dispatch(
        orderActions.loadItem({
          id: orderId,
          include: {
            customerProfile: true,
            orderCards: {
              include: {
                orderCardAttributes: {
                  include: {
                    orderCardAttributeType: true,
                  },
                },
              },
            },
            orderServiceLinkages: {
              include: {
                offeredService: true,
              },
            },
            orderSource: true,
            orderStatus: true,
          },
        }),
      );
      this.actionLogsWhere = {
        OR: [
          {
            parentResourceType: 'order',
            parentResourceId: orderId,
          },
          {
            resourceType: 'order',
            resourceId: orderId,
          },
        ],
      };
    });

    this.currentCard$?.subscribe((card) => {
      const tradingCardType = card?.orderCardAttributes?.find(
        (ca) => ca.orderCardAttributeType?.fieldName == 'tradingCardType',
      )?.value;

      this.model = {
        ...this.model,
        value: card?.value as any,
        tradingCardType,
        ...card?.orderCardAttributes?.reduce(
          (obj, attribute) => ({
            ...obj,
            [attribute.orderCardAttributeType?.fieldName]:
              cardFormArrayFields.includes(
                attribute.orderCardAttributeType?.fieldName,
              )
                ? JSON.parse(attribute.value)
                : attribute.value,
          }),
          {} as CardFormModel,
        ),
      };
    });

    this.summaryTableConfig = {
      ...this.summaryTableConfig,
      data$: this.order$.pipe(
        map((order) => [
          {
            attribute: 'Customer',
            value: `${order?.customerProfile?.firstName ?? ''} ${
              order?.customerProfile?.lastName ?? ''
            }`,
          },
          {
            attribute: 'Requested Services',
            value: (order?.orderServiceLinkages ?? [])
              .map((li) => li.offeredService?.name)
              .join(', '),
          },
          {
            attribute: 'Notes',
            value: order?.notes,
          },
        ]),
      ),
    };
  }

  async openEditForm() {
    const order = await firstValueFrom(this.order$);
    if (!order) return;
    const data: OrderFormModalData = {
      mode: 'update',
      model: {
        ...order,
        requestedServices: order.orderServiceLinkages?.map(
          (li) => li.offeredServiceId,
        ),
      },
    } as any;
    this.dialog.open(OrderFormModalComponent, {
      data,
      minWidth: '50vw',
      maxWidth: 'calc(100vw - 3rem)',
      maxHeight: 'calc(100vh - 3rem)',
      autoFocus: false,
    });
  }

  async promptDeleteItem() {
    const order = await firstValueFrom(this.order$);
    const data: ConfirmDialogData = {
      title: 'Are you sure you want to delete this item?',
      subtitle: 'This action cannot be undone.',
    };
    const confirmation = await lastValueFrom(
      this.dialog
        .open(ConfirmDialogComponent, {
          autoFocus: false,
          data,
        })
        .afterClosed(),
    );

    if (confirmation && order) {
      // TODO: make this also work with non 'id' primary keys
      this.store.dispatch(orderActions.deleteItem({ id: order.id }));
      this.router.navigate(['/']);
    }
  }

  getTextColorForBackground = computeTextColorForBackground;

  async setCurrentCard(
    orderCard: OrderCardResourceTypeMappings['resourceWithRelationsT'] | null,
  ) {
    this.form.reset();
    if (orderCard != null) {
      const copyOfOrderCard = structuredClone(orderCard);
      copyOfOrderCard.frontImageURL = (
        await lastValueFrom(
          this.storageService.getFreshURL(
            copyOfOrderCard.frontImageStorageSlug ?? '',
          ),
        )
      ).url;
      copyOfOrderCard.backImageURL = (
        await lastValueFrom(
          this.storageService.getFreshURL(
            copyOfOrderCard.backImageStorageSlug ?? '',
          ),
        )
      ).url;
      this.currentCard$.next(copyOfOrderCard);
    } else {
      this.currentCard$.next(orderCard);
    }
  }

  async handleImageClick(isBackImage = false) {
    const currentCard = await firstValueFrom(this.currentCard$);
    if (currentCard) {
      const newSrc = (
        await lastValueFrom(
          this.storageService.getFreshURL(
            isBackImage
              ? currentCard.backImageStorageSlug!
              : currentCard.frontImageStorageSlug!,
          ),
        )
      )?.url;
      this.enlargePhoto(newSrc);
    }
  }

  enlargePhoto(src: string) {
    const img = new Image();
    img.src = src;
    // const photoViewer = new PhotoViewer([img], {
    //   title: false,
    // });
  }

  async updateOrderStatus(newStatusId: number) {
    const orderId = await firstValueFrom(this.orderId$);
    if (orderId) {
      this.store.dispatch(
        orderActions.updateItem({
          id: orderId,
          data: {
            orderStatusId: newStatusId,
          },
        }),
      );
    }
  }

  async updateOrderSource(newSourceId: number) {
    const orderId = await firstValueFrom(this.orderId$);
    if (orderId) {
      this.store.dispatch(
        orderActions.updateItem({
          id: orderId,
          data: {
            orderSourceId: newSourceId,
          },
        }),
      );
    }
  }

  getActionLogSummary = getActionLogSummaryString;

  cancel() {
    this.setCurrentCard(null);
  }

  async save() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const card = await firstValueFrom(this.currentCard$);
    const cardId = card?.id;
    const currentOrderCardAttributes = card?.orderCardAttributes;

    if (cardId) {
      this.store.dispatch(
        orderCardActions.updateCardWithAttributes({
          id: cardId,
          cardUpdates:
            Object.entries(this.model)
              .filter(([key, value]) => flatLevelAttributes.includes(key))
              .reduce(
                (obj, [key, value]) => ({
                  ...obj,
                  [key]: value,
                }),
                {} as UpdateOrderCardDTO,
              ) ?? {},
          attributes: Object.entries(this.model)
            .filter(([key, value]) => !flatLevelAttributes.includes(key))
            .map(([key, value]) => ({
              orderCardId: cardId,
              orderCardAttributeTypeId: currentOrderCardAttributes?.find(
                (ca) => ca.orderCardAttributeType.fieldName == key,
              )?.orderCardAttributeTypeId,
              fieldName: key,
              value: cardFormArrayFields.includes(key)
                ? JSON.stringify(value)
                : value,
            })),
        }),
      );
    }
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }
}
