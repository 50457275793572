<div class="container">
  <div class="card header">
    <div class="top">
      <div class="section">
        <h3>Order - {{ orderId$ | async }}</h3>
        <h1>{{ (order$ | async)?.name }}</h1>
      </div>

      <div class="section actions">
        <div class="quick-change-action">
          <p>Source</p>

          <button
            mat-flat-button
            [ngStyle]="{
              'background-color':
                (order$ | async)?.orderSource?.hexColor ?? '#ffffff',
              color: getTextColorForBackground(
                (order$ | async)?.orderSource?.hexColor ?? '#ffffff'
              )
            }"
            [matMenuTriggerFor]="orderSourceMenu">
            {{ (order$ | async)?.orderSource?.name }}
          </button>
          <mat-menu #orderSourceMenu class="like-select" xPosition="before">
            @for (option of orderSources$ | async; track option.id) {
              <button
                mat-flat-button
                [ngStyle]="{
                  'background-color': option?.hexColor ?? '#ffffff',
                  color: getTextColorForBackground(
                    option?.hexColor ?? '#ffffff'
                  )
                }"
                (click)="updateOrderSource(option.id)">
                {{ option.name }}
              </button>
            }
          </mat-menu>
        </div>

        <div class="quick-change-action">
          <p>Status</p>

          <button
            mat-flat-button
            [ngStyle]="{
              'background-color':
                (order$ | async)?.orderStatus?.hexColor ?? '#ffffff',
              color: getTextColorForBackground(
                (order$ | async)?.orderStatus?.hexColor ?? '#ffffff'
              )
            }"
            [matMenuTriggerFor]="orderStatusMenu">
            {{ (order$ | async)?.orderStatus?.name }}
          </button>
          <mat-menu #orderStatusMenu class="like-select" xPosition="before">
            @for (option of orderStatuses$ | async; track option.id) {
              <button
                mat-flat-button
                [ngStyle]="{
                  'background-color': option?.hexColor ?? '#ffffff',
                  color: getTextColorForBackground(
                    option?.hexColor ?? '#ffffff'
                  )
                }"
                (click)="updateOrderStatus(option.id)">
                {{ option.name }}
              </button>
            }
          </mat-menu>
        </div>

        <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #actionsMenu="matMenu" class="menu">
          <button mat-flat-button (click)="openEditForm()">
            <mat-icon iconPositionEnd>edit</mat-icon> Edit
          </button>
          <button mat-flat-button (click)="promptDeleteItem()">
            <mat-icon iconPositionEnd>delete</mat-icon> Delete
          </button>
        </mat-menu>
      </div>
    </div>

    <aa-data-table [tableConfig]="summaryTableConfig"> </aa-data-table>
  </div>

  <div class="card">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <!-- cards -->
      <mat-tab label="Cards">
        <div class="mat-tab-content">
          <h2>Cards</h2>
          <div class="order-cards">
            @for (card of (order$ | async)?.orderCards; track card.id) {
              <img (click)="setCurrentCard(card)" [src]="card.frontImageURL" />
            }
          </div>

          @if ((currentCard$ | async) != null) {
            <div class="card-editor">
              <div class="section">
                <h2>Images</h2>
                <div class="images">
                  <img
                    [src]="(currentCard$ | async)!.frontImageURL"
                    (click)="handleImageClick(false)"
                    width="200"
                    alt="Card Front" />
                  <img
                    [src]="(currentCard$ | async)!.backImageURL"
                    (click)="handleImageClick(true)"
                    width="200"
                    alt="Card Back" />
                </div>
              </div>

              <div class="section">
                <h2>Attributes</h2>
                @if (aiLoading$ | async) {
                  <div class="ai-loading">
                    <p>Detecting Attributes</p>
                    <mat-spinner></mat-spinner>
                  </div>
                }
                <form [formGroup]="form">
                  <formly-form [form]="form" [fields]="fields" [model]="model">
                  </formly-form>
                </form>

                <div class="actions">
                  <button mat-flat-button color="warn" (click)="cancel()">
                    Cancel
                  </button>
                  <button
                    mat-flat-button
                    color="primary"
                    [disabled]="!form.valid || !form.dirty"
                    (click)="save()">
                    Save
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      </mat-tab>

      <mat-tab label="History">
        <div class="mat-tab-content">
          <aa-action-logs-list-view
            [where]="this.actionLogsWhere"
            [include]="actionLogsInclude"
            [inComponent]="true"></aa-action-logs-list-view>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
